import React, { useEffect, useRef, useState } from "react"
import ReactPaginate from "react-paginate"
import Slider from "react-slick"
import styled from "styled-components"
import Section from "../components/common/Section"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../components/ui/navbar"
import { NextArrow, PrevArrow } from "../utils/imgImport"
import { graphql } from "gatsby"
import { rangeRight } from "lodash-es"
import { down } from "styled-breakpoints"
import { ArrayParam, useQueryParam, withDefault } from "use-query-params"
import ContentBox, { ContentBox3 } from "../components/common/ContentBox"
import FilterItem from "../components/common/FilterItem"
import FilterWrapper from "../components/common/FilterWrapper"
import FormInput from "../components/common/FormInput"
import FilterPanel from "../components/Filters/FilterPanel"
import { Typography } from "../components/styled"
import { useDebounce } from "usehooks-ts"
import queryString from "query-string"
import { useTranslation } from "gatsby-plugin-react-i18next"
import dayjs from "dayjs"
import { Index } from "lunr"

const HeaderContainer = styled.div`
  //style={{ paddingTop: "50px", paddingBottom: "155px" }}
  padding-top: 50px;
  padding-bottom: 155px;
  ${down("lg")} {
    padding-top: 30px;
    padding-bottom: 50px;
  }
`
export default function Page({ location, data }) {
  const PER_PAGE = 9
  const query = queryString.parse(location.search)
  const { t } = useTranslation()
  const resourcesData = data.allPrismicResource.nodes
  const categoryData = data.allPrismicCategory.nodes
  const featuredData = data.allPrismicFeatured.nodes[0].data.featured_resources
  const [searchPhrase, setSearchPhrase] = useState(query.q || "")
  const debouncedSearchPhrase = useDebounce(searchPhrase, 300)
  const [category_filter, setCategoryFilter] = useState([])
  const [years, setYears] = useState([])
  const [content_filter, setContentFilter] = useQueryParam(
    "content_type",
    withDefault(ArrayParam, [])
  )

  const [filteredItems, setFilteredItems] = useState(resourcesData)

  const [pageInfo, setPageInfo] = useState({
    totalCount: 0,
    itemOffset: 0,
    pageCount: 0,
  })

  useEffect(() => {
    let newFilteredItems = resourcesData.filter(item => {
      return (
        (category_filter.length === 0 ||
          category_filter.includes(
            categoryData?.find(i => i.prismicId === item.data.category.id)?.data
              .title
          )) &&
        (content_filter.length === 0 ||
          content_filter.includes(item.data.resource_type))
      )
    })

    if (years.length) {
      newFilteredItems = newFilteredItems.filter(x =>
        years.includes(dayjs(x.data.post_date).year())
      )
    }

    if (debouncedSearchPhrase) {
      try {
        const index = Index.load(data.LunrIndex.index)
        const { store } = data.LunrIndex
        const search = index
          .search(`+*${debouncedSearchPhrase.toLowerCase()}*`)
          .map(({ ref }) => {
            return {
              slug: ref,
              ...store[ref],
            }
          })
        const slugs = search.map(x => x.slug)

        newFilteredItems = newFilteredItems.filter(x => slugs.includes(x.uid))
      } catch (error) {
        console.log(error)
      }
    }

    setFilteredItems(newFilteredItems)
    let totalCount = newFilteredItems.length
    let itemOffset = 0
    let pageCount = Math.ceil(totalCount / PER_PAGE)
    setPageInfo({
      totalCount,
      itemOffset,
      pageCount,
    })
  }, [
    category_filter,
    content_filter,
    resourcesData,
    categoryData,
    debouncedSearchPhrase,
    data.LunrIndex,
    years,
  ])

  useEffect(() => {}, [pageInfo])

  const handlePageClick = event => {
    const itemOffset = (event.selected * PER_PAGE) % filteredItems.length

    setPageInfo({
      ...pageInfo,
      itemOffset,
    })
  }

  const handleSearchPhraseChange = e => {
    const query = e.target.value
    setSearchPhrase(query)
  }

  return (
    <Layout location={location}>
      <Seo title="Resources" />
      <Section className="intro-section">
        <Navbar type="second" />
        <HeaderContainer className="container">
          <h1 className="text-white mb-5">Σημαντικά νέα</h1>
          <ResourceSlider data={featuredData} categoryData={categoryData} />
        </HeaderContainer>
      </Section>
      <Section className="activity-section">
        <div className="container">
          <h1 className="mt-5 mb-5">Περιηγηθείτε στα νέα</h1>
          <FilterPanel
            category_filter={category_filter}
            content_filter={content_filter}
            setCategoryFilter={setCategoryFilter}
            setContentFilter={setContentFilter}
            filteredItems={filteredItems}
          />
          <FilterWrapper>
            <Typography className="me-3" variant="detail_bold">
              Έτος
            </Typography>
            <div className="d-flex flex-wrap">
              {["Όλα", ...rangeRight(new Date().getFullYear(), 2016)].map(
                (item, idx) => (
                  <FilterItem
                    key={idx}
                    selected={
                      years.length ? years.includes(item) : item === "Όλα"
                    }
                    onClick={() => {
                      if (item === "Όλα") {
                        setYears([])
                      } else {
                        if (years.includes(item)) {
                          setYears(years => years.filter(year => year !== item))
                        } else {
                          setYears(years => [...years, item])
                        }
                      }
                    }}
                  >
                    {item}
                  </FilterItem>
                )
              )}
            </div>
          </FilterWrapper>
          <div
            css={`
              max-width: 450px;
              margin: 0 auto;
              padding-bottom: 32px;
            `}
          >
            <FormInput
              placeholder={t("common:searchHere")}
              type="search"
              value={searchPhrase}
              onChange={handleSearchPhraseChange}
            />
          </div>
          <div className="desktop-activities">
            <div className="row mt-5">
              {filteredItems
                .slice(pageInfo.itemOffset, pageInfo.itemOffset + PER_PAGE)
                .map((item, idx) => (
                  <div
                    className="col-sm-6 col-lg-4 d-flex justify-content-center"
                    key={idx}
                  >
                    <ContentBox
                      resourceData={item}
                      categoryData={categoryData}
                      width="300px"
                      dashboard={true}
                    />
                  </div>
                ))}
            </div>
            <ReactPaginate
              breakLabel="..."
              onPageChange={e => handlePageClick(e)}
              pageRangeDisplayed={10}
              pageCount={pageInfo.pageCount}
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination-link"}
              nextLinkClassName={"pagination-link"}
              disabledClassName={"pagination-link__disabled"}
              activeClassName={"pagination-link__active"}
              previousLabel="Προηγούμενο"
              nextLabel="Επόμενο"
            />
          </div>

          <div className="mobile-activities">
            {filteredItems.map((item, idx) => (
              <ContentBox3
                resourceData={item}
                categoryData={categoryData}
                width="100%"
                style={{ marginBottom: "40px" }}
                dashboard={true}
                key={idx}
              />
            ))}
          </div>
        </div>
      </Section>
    </Layout>
  )
}
const ResourceSlider = ({ data, categoryData }) => {
  const slider = useRef()
  const next = () => {
    slider.current.slickNext()
  }
  const previous = () => {
    slider.current.slickPrev()
  }

  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    customPaging: i => (
      <div
        style={{
          fontFamily: "CeraGR-Regular",
          fontSize: "18px",
          lineHeight: "32px",
          color: "white",
        }}
      >
        {i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <div className="desktop-activities">
        <div className="resource-slider">
          <div className="slick-arrows">
            <button onClick={previous}>
              <img src={PrevArrow} alt="prev arrow" />
            </button>
            <button onClick={next}>
              <img src={NextArrow} alt="next arrow" />
            </button>
          </div>
          <Slider ref={c => (slider.current = c)} {...settings}>
            {data.map((item, idx) => (
              <div key={idx}>
                <div className="d-flex justify-content-center">
                  <ContentBox
                    resourceData={item.featured.document}
                    categoryData={categoryData}
                    width="300px"
                    height="400px"
                    dashboard={true}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="mobile-activities">
        <div className="overflow-auto">
          <div className="d-flex w-auto">
            {data.map((item, idx) => (
              <div key={idx}>
                <div className="d-flex justify-content-center">
                  <ContentBox
                    resourceData={item.featured.document}
                    categoryData={categoryData}
                    width="300px"
                    height="400px"
                    dashboard={true}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ResourcesQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicResource(
      limit: 300
      sort: { fields: data___post_date, order: DESC }
    ) {
      nodes {
        data {
          category {
            id
            uid
          }
          categories {
            item {
              id
            }
          }
          title
          resource_type
          resource_photo {
            gatsbyImageData
            url
          }
          post_date
          overlay_position
          group_of_subtags {
            subtag {
              id
              uid
            }
          }
        }
        uid
        prismicId
      }
    }
    LunrIndex
    allPrismicFeatured {
      nodes {
        data {
          featured_resources {
            featured {
              id
              document {
                ... on PrismicResource {
                  prismicId
                  uid
                  data {
                    category {
                      id
                      uid
                    }
                    categories {
                      item {
                        id
                      }
                    }
                    title
                    resource_type
                    resource_photo {
                      gatsbyImageData
                      url
                    }
                    post_date
                    overlay_position
                    group_of_subtags {
                      subtag {
                        id
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCategory(filter: { lang: { eq: "el-gr" } }) {
      nodes {
        prismicId
        data {
          title
          color
          icon {
            url
          }
          overlay_img {
            url
          }
        }
      }
    }
  }
`
